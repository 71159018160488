<template>
  <div class="coupe-de-france-du-potager-evaluation max-w-2xl mt-1">

    <div class="w-100 d-flex align-items-center justify-content-between">
      <b-button
          variant="outline-primary"
          @click="$router.push({name: 'coupe-de-france-du-potager'})">
        <feather-icon
            class="mr-50"
            icon="LogoutIcon"
            size="16"/>
        <span class="align-middle"> Entregistrer et quitter </span>
      </b-button>
      <b-button
          class="p-0"
          variant="flat-primary"
          @click="helpPopUp = true">
        <feather-icon
            icon="HelpCircleIcon"
            size="24"/>
      </b-button>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <img
          alt="logo coupe du monde du potager"
          class="height-100"
          src="@/assets/images/cfp/logo_coupe.png">
    </div>

    <CFPCategories v-if="establishmentType.id" :establishmentType="establishmentType"/>

    <div class="my-3 d-flex flex-column align-items-center">
      <b-button
          class="mb-50"
          variant="primary"
          @click="welcomePopUp = true">
        <feather-icon
            class="mr-50"
            icon="FileTextIcon"
            size="16"/>
        <span class="align-middle"> Message de bienvenue & Consignes pour l'évaluation </span>
      </b-button>

      <b-button
          class="mb-50"
          variant="info"
          @click="helpSheetsPopUp = true">
        <feather-icon
            class="mr-50"
            icon="FileTextIcon"
            size="16"/>
        <span class="align-middle"> Fiches pratiques </span>
      </b-button>

      <b-button
          class="mb-50"
          variant="warning"
          @click="hurryPopUp = true">
        <feather-icon
            class="mr-50"
            icon="ClockIcon"
            size="16"/>
        <span class="align-middle">  Manque de temps - je soumets le version simplifiée de l'auto-évaluation </span>
      </b-button>
    </div>

    <b-modal
        v-model="welcomePopUp"
        centered
        hide-footer
        title="Bienvenue">
      <CFPRules v-if="establishmentType" :establishmentType="establishmentType"/>
    </b-modal>

    <b-modal
        v-model="hurryPopUp"
        centered
        hide-footer
        title="Version simplifiée de l'auto-évaluation">
      <p class="text-justify mb-1">
        Si vous n’avez pas le temps de remplir le formulaire d’auto-évaluation, vous pouvez toujours concourir à la
        Coupe de France du potager, dans la catégorie novice. Pour cela, il vous suffit de remplir notre court
        formulaire sur votre potager et à l’envoyer à
        <a :href="'mailto:'+landestiniContactEmail">
          {{ landestiniContactEmail }}
        </a>
      </p>

      <p>
        Vous aurez ainsi la possibilité de remporter les mêmes prix, dans la catégorie novice.
      </p>

      <div class="d-flex justify-content-center w-100 mt-1">
        <b-button
            class="mb-50"
            variant="primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Liens vers le formulaire </span>
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-model="helpSheetsPopUp"
        centered
        hide-footer
        title="Fiches pratiques">
      <div class="flex-column">
        <h4 class="font-weight-bold">
          La biodiversité c'est carré
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openHelpSheet(13)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle"> Réussir ses semis </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="openHelpSheet(15)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle"> Fabriquer un abri à insecte </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Ça nous nourrit
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="openHelpSheet(3)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle">  Les déchets végétaux </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="openHelpSheet(8)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle"> Le compost </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Mon bo potager
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          On est prêts
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openHelpSheet(14)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle"> Le sol au potager </span>
        </b-button>
        <br>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="openHelpSheet(6)">
          <feather-icon
              class="mr-50"
              icon="FileTextIcon"
              size="16"/>
          <span class="align-middle"> Gestion de l'eau </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          Ensemble on va plus loin
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>

        <h4 class="font-weight-bold mt-1">
          J'apprends je partage
        </h4>
        <b-button
            class="mb-50"
            variant="flat-primary"
            @click="$openLinkJaya('https://landestini.org/nous-vous-guidons-2022/')">
          <feather-icon
              class="mr-50"
              icon="ExternalLinkIcon"
              size="16"/>
          <span class="align-middle">  Landestini : Nous vous guidons </span>
        </b-button>
      </div>
      <b-modal
          v-if="activeSheet"
          v-model="helpSheetPopUp"
          :title="activeSheet.title"
          centered
          hide-footer>
        <HelpSheet :sheet="activeSheet"/>
      </b-modal>
    </b-modal>

    <b-modal
        v-model="helpPopUp"
        centered
        hide-footer
        title="Besoin d'aide">
      <div class="d-flex align-items-center">
        <img
            alt="Icone de feuille"
            class="mx-1"
            src="@/assets/images/icons/other/question_bubbles.svg"
            style="height: 35px;min-width:40px;">
        <p class="p-0 m-0">
          Une question ou un commentaire sur l’évaluation et ses questions ? Écrivez à l’adresse
          <br>
          <a class="underline" href="mailto:coupedefrancedupotager@landestini.org">
            coupedefrancedupotager&#64;landestini&#46;org
          </a>
        </p>
      </div>
      <div class="d-flex align-items-center pt-50">
        <img
            alt="Icone de feedback negatif"
            class="mx-1"
            src="@/assets/images/icons/other/feedback_negative.svg"
            style="height: 35px;min-width:40px;">
        <p class="p-0 m-0">
          Un problème technique dans le formulaire d’évaluation ? Ecrivez à
          <br>
          <a class="underline" href="mailto:contact@hortilio.fr">
            contact&#64;hortilio&#46;fr
          </a>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CFPRules from '@/components/cfp/CFPRules.vue'
import CFPCategories from '@/components/cfp/CFPCategories.vue'
import {BButton, BModal} from "bootstrap-vue";
import HelpSheet from "@/components/help-sheets/HelpSheet";

export default {
  components: {
    BButton,
    BModal,
    CFPCategories,
    CFPRules,
    HelpSheet,
  },
  data() {
    return {
      welcomePopUp: false,
      hurryPopUp: false,
      helpPopUp: false,
      helpSheetsPopUp: false,
      helpSheetPopUp: false,
      activeSheet: null,
      landestiniContactEmail: 'coupedefrancedupotager@landestini.org',
    }
  },
  beforeMount() {
    if (this.$store.state.cfp.gotQuiz) {
      this.welcomePopUp = true // todoCFP: set true only if user has not yet entered any quiz answers
    } else {
      this.$router.push({name: 'coupe-de-france-du-potager'})
    }
  },
  computed: {
    establishmentType() {
      if (this.$route.params.establishmentType) {
        return this.$store.state.cfp.establishmentTypes.find(type => type.routeName === this.$route.params.establishmentType)
      }
      return null
    }
  },
  methods: {
    openHelpSheet(helpSheetId) {
      this.$store.dispatch("helpSheet/get", helpSheetId)
          .then(response => {
            this.activeSheet = response.data
            this.helpSheetPopUp = true
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Une erreur est survenue, veuillez réessayer plus tard",
              color: "danger",
              time: 4000
            })
          })
    }
  }
}
</script>


<style lang="scss">
.coupe-de-france-du-potager-evaluation {
  p {
    padding-top: 1rem;
  }
}
</style>
