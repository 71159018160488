<template>
  <div>
    <b-row v-if="gotQuiz" :key="scoreKey">
      <b-col
          v-for="(category, index) of orderedCategories"
          :key="index"
          cols="6"
          md="4">
        <div
            class="h-100 d-flex justify-content-center align-items-center">
          <div class="position-relative container">
            <img
                :alt="ImageDescription(category.title)"
                :src="getImage(category.title)"
                class="p-50 w-100 cursor-pointer hover-bg-primary-light"
                @click="openCategory(category)">
            <div class="position-absolute position-bottom-0 position-right-0">
              <div v-if="category.score && category.score > 0"
                  class="d-flex align-items-center bg-primary text-white px-50 py-25 rounded-lg m-1 mb-2">
                {{ category.score }}
                <img :alt="ImageDescription('Patate')" :src="getImage('Patate')" class="ml-50 height-20">
              </div>
            </div>
            <div v-if="category.title === 'VOTRE ÉQUIPE'" class="position-absolute position-top-0 w-100 text-center">
              <span
                  class="font-medium-3 font-weight-bold"
                  style="color: #185650;">
                VOTRE ÉQUIPE !
              </span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <JayaPopUp
        ref="categoryPopUp"
        :name="activeCategory ? activeCategory.title.replace(/\s/g, '') : ''">
      <div v-if="activeCategory" class="w-100 max-w-xl">
        <b-row>
          <b-col cols="3">
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="dark"
                @click="$refs.categoryPopUp.closePopUp()">
              <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"/>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="6">
            <img :alt="ImageDescription(activeCategory.title)" :src="getImage(activeCategory.title)" style="max-width:100%;max-height:200px;">
          </b-col>
        </b-row>

        <b-card
            v-if="activeCategory.description"
            border-variant="secondary"
            class="text-justify max-w-lg shadow-none">
            {{ activeCategory.description }}
        </b-card>

        <b-card
            v-for="(subcategory, index) in orderedActiveSubCategories"
            :key="index"
            body-class="p-25"
            class="cursor-pointer hover-bg-primary-light mt-2"
            @click="openQuiz(subcategory)">
          <div class="w-100 h-100 pl-1 d-flex justify-content-between align-items-center" style="min-height: 60px;">
            <span class="font-weight-bold"> {{ subcategory.title !== '' ? subcategory.title : activeCategory.title }}</span>
            <div class="float-right m-50 mr-1">
              <div class="d-flex text-lg text-white bg-primary px-1 py-50 rounded-lg" :class="subcategory.score ? '' : 'score-grayscale'">
                {{ subcategory.score }}
                <img :alt="ImageDescription('Patate')" :src="getImage('Patate')" class="ml-25 height-20">
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </JayaPopUp>

    <JayaPopUp
        ref="quizPopUp"
        :name="activeSubcategory ? activeSubcategory.title.replace(/\s/g, '') : ''"
        @close="openCategory(activeCategory)">
      <div v-if="activeCategory && activeSubcategory" class="w-100 max-w-xl">
        <b-row>
          <b-col cols="3">
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="dark"
                @click="$refs.quizPopUp.closePopUp()">
              <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"/>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="6">
            <h1 class="mt-1 text-center text-color-base w-66">
              {{ activeSubcategory.title }}
            </h1>
          </b-col>
        </b-row>

        <CFPQuiz
            :establishmentType="establishmentType"
            :quiz="quiz"
            :sentQuizAnswerer="quizAnswerer"
            :subCategory="activeSubcategory"
            @dataSaved="unsavedModifications = false"
            @quizAnswered="newAnswers"
            @unsavedData="unsavedModifications = true"
            @updateQuizAnswerer="updateQuizAnswerer"/>
      </div>
    </JayaPopUp>

    <b-modal
        v-model="saveGuardPrompt"
        cancel-title="Annuler"
        ok-title="Continuer"
        title="Modifications non enregistré"
        @ok="stepBackWithoutSave">
      Attention vous n'avez pas enregistré vos modifications. Voulez-vous quitter sans sauvegarder ?
    </b-modal>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import JayaPopUp from '@/components/global/JayaPopUp.vue'
import CFPQuiz from '@/components/cfp/CFPQuiz.vue'
import _ from 'lodash'
import {BButton, BCard, BCol, BModal, BRow} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    BCard,
    JayaPopUp,
    CFPQuiz
  },
  props: {
    establishmentType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
      activeSubcategory: null,
      saveGuardPrompt: false,
      unsavedModifications: false,
      quiz: {},
      gotQuiz: false,
      quizAnswerer: null,
      scoreKey: 0
    }
  },
  mounted() {
    this.$loading(true)
    this.$store.dispatch("cfp/retrieveQuiz", {id: this.establishmentType.id})
        .then(response => {
          this.quiz = response.data
          this.gotQuiz = true
          this.getScores()
          this.$loading(false)
        })
        .catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur au téléchargement du quiz",
            text: "Veuillez réessayer ulterieurement",
            color: "danger"
          })
          this.$loading(false)
        })
  },
  computed: {
    orderedCategories() {
      return _.orderBy(this.quiz.categories, 'order')
    },
    orderedActiveSubCategories() {
      return _.orderBy(this.activeCategory.sub_categories, 'order')
    }
  },
  methods: {
    openCategory(category) {
      this.activeCategory = category
      this.$refs.categoryPopUp.openPopUp()
    },
    openQuiz(subCategory) {
      this.activeSubcategory = subCategory
      this.$refs.categoryPopUp.closePopUp()
      this.$refs.quizPopUp.openPopUp()
    },
    stepBack() {
      if (this.activeSubcategory && this.activeSubcategory.title !== '') {
        if(this.unsavedModifications) {
          this.saveGuardPrompt = true
        } else {
          this.activeSubcategory = null
          this.$refs.quizPopUp.closePopUp()
        }
      } else {
        this.activeSubcategory = null
        this.$refs.quizPopUp.closePopUp()
      }
    },
    stepBackWithoutSave() {
      this.unsavedModifications = false
      this.activeSubcategory = null
    },
    getImage(image) {
      let img = ""
      let imageName = "default"
      if (this.$store.state.cfp.categoriesImages.filter(item => item.title === image).length > 0) {
        imageName = this.$store.state.cfp.categoriesImages.find(item => item.title === image).imageName
      }
      if (image === "Patate") {
        imageName = "Patate"
      }
      try {
        img = require("@/assets/images/cfp/" + imageName + ".png")
      } finally {
        // do nothing
      }
      return img
    },
    ImageDescription(object) {
      return "Image d'un pictogramme de " + object
    },
    getCriteria(category) {
      let resultArray = []
      for (const subcategory of category.subcategories) {
        for (const criteria of subcategory.criteria) {
          resultArray.push(criteria)
        }
      }
      return resultArray
    },
    newAnswers() {
      this.stepBack()
      this.getScores()
    },
    getScores() {
      if (this.quiz.id) {
        this.$store.dispatch("cfp/getQuizAnswerer", {quiz: this.quiz.id})
            .then(response => {
              if (response.data.length > 0 && response.data.some(quizAnswerer => quizAnswerer.quiz === this.quiz.id)) {
                this.quizAnswerer = response.data.find(quizAnswerer => quizAnswerer.quiz === this.quiz.id)
                let answersGiven = this.quizAnswerer.answers_given
                let categoryScoreSum = 0
                let subCategoryScoreSum = 0
                for (const category of this.quiz.categories) {
                  categoryScoreSum = 0
                  for (const subCategory of category.sub_categories) {
                    subCategoryScoreSum = 0
                    for (const subSubCategory of subCategory.sub_sub_categories) {
                      for (const question of subSubCategory.questions) {
                        if (answersGiven.some(answer => answer.question === question.id)) {
                          let score = this.calcScore(question, answersGiven.find(answer => answer.question === question.id))
                          categoryScoreSum = categoryScoreSum + score
                          subCategoryScoreSum = subCategoryScoreSum + score
                        }
                      }
                    }
                    if (subCategoryScoreSum > 0) {
                      subCategory.score = subCategoryScoreSum
                    }
                  }
                  if (categoryScoreSum > 0) {
                    category.score = categoryScoreSum
                  }
                }
                this.scoreKey = this.scoreKey + 1
              }
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur",
                text: "Les scores n'ont pas réussi à être calculés",
                color: "danger"
              })
            })
      }
    },
    calcScore(question, answer) {
      if (question.score) {
        if (question.type === 'NU') {
          //todoCFP add score max
          return question.score * answer.number_answer
        } else if (answer.boolean_answer || answer.answers.length > 0 || answer.text_answer !== '' || answer.justifications.length > 0) {
          return question.score
        }
      }
      return 0
    },
    updateQuizAnswerer(newAnswerer) {
      this.quizAnswerer = newAnswerer
    }
  }
}
</script>

<style lang="scss">
.wizard-tab-content {
  padding: 10px !important;
}
.score-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>
