<template>
  <div class="coupe-de-france-du-potager-rules text-justify">
    <h5 class="text-primary">
      Bienvenue sur le formualire de l’auto-évaluation de la Coupe de France du potager
      {{ this.establishmentType.typeText !== '' ? 'pour ' + this.establishmentType.typeText : '' }} !
    </h5>

    <p>
      Cette auto-évaluation constitue la première étape de l’évaluation de la Coupe de France du potager, avant
      l’examen des dossiers par le jury.
      <br>
      Ci-dessous, vous trouverez les critères sur lesquels votre potager sera évalué, ainsi que le critère
      “mini-pousses” pour les potagers de 6 à 10m2 et la question “bonus-bonus” (expression libre sur votre potager).
      Chaque critère comprend plusieurs questions : ce sont le plus souvent des questions à choix multiples, des
      oui/non et quelques questions à texte.
      <p v-if="establishmentType.id !== 1 && establishmentType.id !== 4">
          <img alt="dessin de patate" class="inline height-20" src="@/assets/images/cfp/Patate.png">
          <strong>
            Dans chaque question, il y a la possibilité de gagner un certain nombre
            de patates (des points).
          </strong>
          Ces patates permettent d'avoir un score par critère et un score global. C’est ce score
          qui permet d’être sélectionné parmi les finalistes pour l’évaluation par le jury. Les questions à réponse texte
          libre servent à évaluer votre potager et votre démarche de manière qualitative par le jury. Une moyenne des deux
          scores sera ensuite faite afin d’élir les équipes lauréates et l’équipe championne !
        </p>
    </p>

    <p v-if="establishmentType.id !== 1 && establishmentType.id !== 4">
      <strong>
        Rappel : Si vous n’avez pas beaucoup de temps à consacrer à ce formulaire, vous n’êtes pas obligé de répondre
        à
        toutes les questions ! Vous pouvez répondre à celles que vous souhaitez, et simplement lire les autres
        questions
        afin d’apprendre sur le potager avec votre équipe.
      </strong>
      Vous pouvez aussi simplement remplir le
      formulaire en répondant aux réponses à texte libre. Certes, vous n’aurez pas beaucoup de points (patates), mais
      cela vous permet tout de même de soumettre votre formulaire et de participer jusqu’au bout !
    </p>

    <p>
      <strong>
        Pour de nombreuses questions, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo.
      </strong>
      Ces justificatifs sont parfois obligatoires et parfois facultatifs, mais renforcent votre dossier pour l’examen
      par
      le jury. Plus vous appuyez votre dossier avec des justificatifs photos (ou dessins ou schémas), plus il sera
      robuste !
    </p>

    <p>
      Vos réponses seront enregistrées au fur et à mesure, ce qui vous permet de traiter les questions progressivement
      et en plusieurs fois.
      <strong>
        Vous avez jusqu’au
        {{ this.establishmentType.end_date.toLocaleDateString('fr-FR', {day: 'numeric', year: 'numeric', month: 'long'}) }}  à 23h59 pour remplir ce formulaire. Vous pouvez le
        remplir et sauvegarder vos réponses au fur et à mesure, dès aujourd’hui.
      </strong>
    </p>

    <p>
      La note globale permet de définir quels dossiers seront envoyés au jury, mais ce n’est pas la seule donnée qui
      rentre en compte ! Vos justificatifs photos, vos réponses libres et vos vidéos seront aussi les éléments que le
      jury évaluera. Nous vous recommandons donc de les soigner.
    </p>

    <p>
      <strong>
        Pour rappel, les valeurs de la Coupe de France du potager sont l’honnêteté, l’engagement, la solidarité, la
        bienveillance, l’entraide et l’humilité.
      </strong>
      Ces valeurs doivent guider les participants, les référents, le jury et
      l’équipe Landestini tout au long du concours. En soumettant votre auto-évaluation, vous attestez sur l’honneur
      de l’exactitude des informations fournies. Nous nous réservons le droit de vous demander des justificatifs
      (photos, vidéos, carnet de bord ou autres éléments) démontrant la véracité de vos réponses. Un membre de
      l’équipe Landestini ou du jury est également susceptible de venir visiter et d’examiner votre potager en cas de
      sélection parmi les équipes lauréates.
    </p>

    <p v-if="establishmentType.id !== 1 && establishmentType.id !== 4">
      <strong>
        Un rappel sur le temps à consacrer au potager pendant le concours :
      </strong>
      nous savons qu’il est nécessaire d’entretenir un potager un peu chaque jour (ou presque, en fonction de la
      saison et des condition climatiques),
      mais qu’il n’est pas nécessaire d’y passer des heures à chaque fois ! C’est pour cela que nous avons établi dans
      le règlement de la Coupe de France du potager que
      <strong>
        les membres de l’équipe devraient chacun passer 2 heures par
        mois à entretenir le potager (soit une demi-heure par semaine).
      </strong>
      Chaque équipe et chaque potager est différent
      dans ses disponibilités, et nous savons aussi que les disponibilités des membres de l’équipe varient selon les
      périodes. Ainsi, cette implication n’est pas stricte et est plutôt une moyenne et une consigne générale.
      <strong>
        L’important est que la démarche soit collective
      </strong>
      - nous définissons une équipe à partir de 5 personnes dont un
      adulte de plus de 18 ans - et que l’équipe crée et entretienne un potager avec enthousiasme durant le concours !
    </p>

    <p>
      Un grand merci et félicitations de nouveau pour votre participation dans cette première édition de la Coupe de
      France du potager
      {{ this.establishmentType.typeText !== '' ? 'pour ' + this.establishmentType.typeText : '' }} !
      ! Même si nous sélectionnons des équipes lauréates et championnes,
      <strong>
        vous êtes tous gagnants en tant que participants et nous espérons que vous vivez une
        belle aventure avec nous !
      </strong>
    </p>

    <p>
      1, 2, 3, potagez !
    </p>

    <b-card v-if="establishmentType.id === 1 || establishmentType.id === 4" bg-variant="primary" class="mt-2 max-w-xl text-justify">
      <div class="d-flex align-items-center text-white">
        <img alt="dessin de patate" class="inline mx-50 height-50" src="@/assets/images/cfp/Patate.png">
        <span> Dans chaque thème, il y a la possiblité de gagner un certain nombre de patates (des points). Ces patates permettent d'avoir un score par thème et un score global. </span>
      </div>
    </b-card>
  </div>
</template>


<script>
import {BCard} from "bootstrap-vue";

export default {
  components: {
    BCard,
  },
  props: {
    establishmentType: {
      type: Object,
      required: true,
    },
  }
}
</script>
