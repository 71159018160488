<template>
  <div class="cdfdp-quiz">
    <b-alert v-if="timeLimitPassed" show class="text-center" variant="danger">
      La date butoire est passée. Vous n'avez plus la possibilité de modifier vos réponses.
    </b-alert>
    <form-wizard
        :subtitle="null"
        :title="null"
        backButtonText="Précédent"
        class="p-0"
        color="var(--primary)"
        errorColor="(--danger)"
        finishButtonText="Enregistrer"
        nextButtonText="Suivant"
        stepSize="xs"
        @on-complete="postAnswers"
        @update:startIndex="changeActive">
      <tab-content
          v-for="(subSubCategory, index) in orderedSubSubCategories"
          :key="index">
        <h2 class="w-100 text-center text-primary mb-3 mt-1">{{ subSubCategory.title }}</h2>
        <div
            v-for="(question, index) in order(subSubCategory.questions)"
            :key="index">
          <b-card
              body-class="p-1"
              border-variant="primary"
              class="w-100 mb-2"
              header-bg-variant="primary"
              header-class="px-1 pt-50 pb-25 text-white">
            <template #header>
              <span class="font-medium-2 pr-1">{{ question.title }}</span>
              <b-badge
                  v-if="question.score && question.score !== 0"
                  class="badge-up font-medium-2 px-50 py-25"
                  variant="dark">
                {{ question.type === 'NU' ? 'x' : '+' }}
                {{ question.score }}
                <img
                    :alt="ImageDescription('Patate')"
                    :src="getImage('Patate')"
                    class="align-bottom pl-25"
                    style="height:16px;">
              </b-badge>
            </template>

            <div v-if="mounted" class="d-flex flex-wrap align-items-center">
              <b-col
                  :class="question.justification_needed ? 'col-md-8' : ''"
                  class="col-12 d-flex justify-content-center">
                <div v-if="question.type === 'YN'">
                  <b-form-checkbox
                      v-model="quizAnswers[question.id]"
                      :disabled="timeLimitPassed"
                      class="custom-control-primary"
                      switch>
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon"/>
                    </span>
                  </b-form-checkbox>
                </div>
                <div v-if="question.type === 'NU'">
                  <b-form-input
                      v-model="quizAnswers[question.id]"
                      :disabled="timeLimitPassed"
                      min="0"
                      type="number"
                      @input="addAnswer(question.id)"/>
                </div>
                <div v-if="question.type === 'TE'" class="w-100">
                  <b-form-textarea
                      v-model="quizAnswers[question.id]"
                      :disabled="timeLimitPassed"/>
                </div>
                <div v-if="question.type === 'MC'">
                  <b-form-group class="mb-0">
                    <b-form-radio
                        v-for="(possibleAnswer, index) in question.possible_answers"
                        :key="index"
                        v-model="quizAnswers[question.id]"
                        :value="possibleAnswer.id">
                      {{ possibleAnswer.text }}
                    </b-form-radio>
                  </b-form-group>
                </div>
              </b-col>

              <b-col v-if="question.justification_needed" class="col-12 col-md-4 d-flex justify-content-center">
                <b-button
                    :variant="quizJustifications[question.id] && quizJustifications[question.id].length > 0 ? 'outline-primary' : 'outline-secondary'"
                    @click="openJustification(question)">
                  <feather-icon
                      class="mr-50"
                      icon="CameraIcon"
                      size="16"/>
                  <span class="align-middle">
                  Justificatif
                  {{
                      quizJustifications[question.id] && quizJustifications[question.id].length > 0 ? '(x' + quizJustifications[question.id].length + ')' : ''
                    }}
                  </span>
                </b-button>
              </b-col>
            </div>
          </b-card>
        </div>
      </tab-content>
    </form-wizard>

    <b-modal
        v-if="activeQuestion && quizJustifications[activeQuestion.id]"
        v-model="justificationPopUp"
        centered
        hide-footer
        title="Justification">
      <h5>Justification pour "{{ activeQuestion.title }}"</h5>
      <div class="w-100 d-flex justify-content-center">
        <b-button variant="primary"
                  @click="uploadPhotoPrompt = true"
                  :disabled="quizJustifications[activeQuestion.id].length >= 2">
          <feather-icon
              class="mr-50"
              icon="PlusIcon"
              size="16"/>
          <span class="align-middle"> Ajouter une photo </span>
        </b-button>
      </div>
      <div
          v-if="quizJustifications[activeQuestion.id].length >= 2"
          class="text-danger text-center">
          Maximum deux photos par question
        </div>

      <jaya-divider color="transparent"/>

      <div v-if="quizJustifications[activeQuestion.id].length > 0">
        <h2 class="text-center"> Vos justifications (x{{ quizJustifications[activeQuestion.id].length }}) : </h2>

        <div class="d-flex justify-content-center flex-wrap">
          <div v-for="(photo, index) in quizJustifications[activeQuestion.id]" :key="index" class="d-flex justify-content-center flex-wrap">
            <div class="position-relative">
              <h3 class="position-absolute top-0 left-0 m-2 bg-white pr-2 pl-2 rounded-full bg-opacity-75"> {{
                  index + 1
                }} </h3>
              <b-button
                  class="position-absolute top-0 right-0 p-50"
                  pill
                  size="sm"
                  variant="danger"
                  @click="removePhotoAsk(photo)">
                <feather-icon
                    icon="TrashIcon"
                    size="16"/>
              </b-button>
              <img :src="photo.url" class="height-100"/>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        Vous n'avez pas encore ajouté de justifications
      </div>

      <jaya-divider color="transparent"/>

      <div class="d-flex justify-content-end">
        <b-button
            class="mb-2"
            variant="primary"
            @click="justificationPopUp = false"
        >
          <span class="align-middle"> Valider </span>
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-model="removePhotoPrompt"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        title="Supprimer"
        variant="danger"
        @ok="removePhoto">
      Êtes-vous sur de vouloir supprimer cette photo de vos justifications ?
    </b-modal>

    <JayUpload :active.sync="uploadPhotoPrompt"
        title="Ajouter une photo"
        @on-cancel="uploadPhotoPrompt = false"
        @on-error="uploadPhotoPrompt = false"
        @on-success="addPhoto"/>
  </div>
</template>

<script>
import JayUpload from '@/components/global/JayUpload'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import _ from 'lodash'
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BModal
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    BCard,
    BAlert,
    BFormRadio,
    BFormGroup,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BBadge,
    JayUpload,
    FormWizard,
    TabContent
  },
  props: {
    quiz: {
      type: Object,
      required: true
    },
    sentQuizAnswerer: {
      type: Object,
      default: null
    },
    subCategory: {
      type: Object,
      required: true
    },
    establishmentType: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      quizAnswerer: null,
      quizAnswers: [],
      quizJustifications: [],
      activePhoto: null,
      activeQuestion: null,
      activeQuestionList: [],
      mounted: false,
      justificationPopUp: false,
      uploadPhotoPrompt: false,
      removePhotoPrompt: false,
    }
  },
  mounted() {
    if (this.sentQuizAnswerer) {
      this.quizAnswerer = this.sentQuizAnswerer
      this.setQuestions()
    } else {
      this.$store.dispatch("cfp/createQuizAnswerer", {quiz: this.quiz.id})
          .then(creationResponse => {
                this.quizAnswerer = creationResponse.data
                this.$emit('updateQuizAnswerer', this.quizAnswerer)
                this.setQuestions()
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              title: "Erreur",
              text: "Veuillez réessayer ulterieurement",
              color: "danger"
            })
          })
    }
  },
  computed: {
    timeLimitPassed() {
      let maxDate = this.establishmentType.end_date
      let now = new Date()
      return (maxDate < now)
    },
    orderedSubSubCategories() {
      return _.orderBy(this.subCategory.sub_sub_categories, 'order')
    }
  },
  methods: {
    setQuestions() {
      let questionPreviousAnswer = null
      let answer = null
      for (let subSubCategory of this.orderedSubSubCategories) {
        for (let question of subSubCategory.questions) {
          answer = null
          questionPreviousAnswer = null
          if (this.quizAnswerer.answers_given.length > 0 && this.quizAnswerer.answers_given.some(answer => answer.question === question.id)) {
            questionPreviousAnswer = this.quizAnswerer.answers_given.find(answer => answer.question === question.id)
          }
          if (question.type === 'NU') {
            questionPreviousAnswer ? answer = questionPreviousAnswer.number_answer : answer = null
          } else if (question.type === 'YN') {
            questionPreviousAnswer ? answer = questionPreviousAnswer.boolean_answer : answer = false
          } else if (question.type === 'TE') {
            questionPreviousAnswer ? answer = questionPreviousAnswer.text_answer : answer = ''
          } else {
            // todo: change if multiple answers possible
            questionPreviousAnswer ? answer = questionPreviousAnswer.answers[0] : answer = null
          }
          this.quizAnswers[question.id] = answer

          let questionJustifications = []
          if (questionPreviousAnswer) {
            for (const image of questionPreviousAnswer.justifications) {
              questionJustifications.push({
                url: process.env.VUE_APP_BACKEND_URL + '/..' + image.photo_url,
                id: image.image
              })
            }
          }
          this.quizJustifications[question.id] = questionJustifications
        }
      }
      this.mounted = true
    },
    getImage(image) {
      let img = ""
      try {
        img = require("@/assets/images/cfp/" + image + ".png")
      } finally {
        // do nothing
      }
      return img
    },
    ImageDescription(object) {
      return "Image d'un pictogramme de " + object
    },
    changeActive(event) {
      this.activeQuestion = event
    },
    async postAnswers() {
      if (this.timeLimitPassed) {
        this.$notify({
          time: 4000,
          title: "Erreur",
          text: "La date butoire est passée. Vous n'avez plus la possibilité de modifier vos réponses.",
          color: "danger"
        })
      } else {
       await Promise.all(this.orderedSubSubCategories.map(async (subSubCategory) => {
         await Promise.all(subSubCategory.questions.map(async (question) => {
            let oldAnswer = null
            if (this.quizAnswerer.answers_given.some(answer => answer.question === question.id)) {
              oldAnswer = this.quizAnswerer.answers_given.find(answer => answer.question === question.id)
              if ((question.type === 'YN' && oldAnswer.boolean_answer !== this.quizAnswers[question.id])
                  ||
                  (question.type === 'NU' && oldAnswer.number_answer !== this.quizAnswers[question.id])
                  ||
                  (question.type === 'TE' && oldAnswer.text_answer !== this.quizAnswers[question.id])
                  ||
                  (question.type === 'MC' && oldAnswer.answers != this.quizAnswers[question.id])
                  ||
                  (this.quizJustifications[question.id] !== oldAnswer.justifications)
              ) {
                await this.postAnswer(question)
              }
            } else if ((question.type === 'YN' && this.quizAnswers[question.id] !== false)
                ||
                (question.type === 'NU' && this.quizAnswers[question.id] !== null)
                ||
                (question.type === 'TE' && this.quizAnswers[question.id] !== '')
                ||
                (question.type === 'MC' && this.quizAnswers[question.id] !== null)
                ||
                (this.quizJustifications[question.id].length > 0)
            ) {
              await this.postAnswer(question)
            }
            else {
              // needs to have a promise anyways
              await new Promise(resolve => {resolve()})
            }
          }))
        }))
        this.$emit('dataSaved')
        this.$emit('quizAnswered')
      }
    },
    postAnswer(question) {
      return new Promise((resolve, reject) => {
        let payload = {
          question: question.id,
          quizanswerer: this.quizAnswerer.id,
        }

        if (question.type === 'YN') {
          payload.boolean_answer = this.quizAnswers[question.id]
        } else if (question.type === 'NU' && this.quizAnswers[question.id] !== '') {
          payload.number_answer = Number(this.quizAnswers[question.id])
        } else if (question.type === 'TE') {
          payload.text_answer = this.quizAnswers[question.id]
        } else if (question.type === 'MC') {
          payload.answers = [this.quizAnswers[question.id]]
        }

        if (this.quizJustifications[question.id]) {
          let justifs = []
          for (const photo of this.quizJustifications[question.id]) {
            justifs.push({image: photo.id})
          }
          payload.justifications = justifs
        }

        if (this.quizAnswerer.answers_given.some(answer => answer.question === question.id)) {
          payload.responseId = this.quizAnswerer.answers_given.find(answer => answer.question === question.id).id
          this.$store.dispatch('cfp/updateQuizResponse', payload)
              .then((result) => {
                resolve(result)
              })
              .catch((error) => {
                reject(error)
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Veuillez réessayer ulterieurement",
                  color: "danger"
                })
              })
        } else {
          this.$store.dispatch('cfp/createQuizResponse', payload)
              .then((result) => {
                resolve(result)
              })
              .catch((error) => {
                reject(error)
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Veuillez réessayer ulterieurement",
                  color: "danger"
                })
              })
        }
      });
    },
    removePhotoAsk(photo) {
      this.activePhoto = photo
      this.removePhotoPrompt = true
    },
    removePhoto() {
      if (this.activeQuestion && this.activePhoto) {
        this.quizJustifications[this.activeQuestion.id] = this.quizJustifications[this.activeQuestion.id].filter(photo => photo.id != this.activePhoto.id)
        let payload = {
          id: this.activePhoto.id
        }
        this.$store.dispatch("feedback/removeJustification", payload)
            .then(() => {
              this.$notify({
                time: 4000,
                title: "Photo retirée",
                color: "primary"
              })
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur à la suppression",
                text: "Veuillez réessayer ulterieurement",
                color: "danger"
              })
            })
      }
    },
    addPhoto(photo) {
      this.quizJustifications[this.activeQuestion.id].push(photo)
      this.$emit('unsavedData')
    },
    order(list) {
      return _.orderBy(list, 'order')
    },
    addAnswer(id) {
      if (isNaN(this.quizAnswers[id])) {
        this.quizAnswers[id] = 0
      }
    },
    openJustification(question) {
      this.activeQuestion = question
      this.justificationPopUp = true
    },
  }
}
</script>

<style lang="scss">
.cdfdp-quiz {
  width: 100%;
  height: 100%;

  .wizard-header {
    padding: 0;
  }

  .checkbox-quiz-element {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .vue-form-wizard .wizard-card-footer {
    padding: 0 10px;
  }

  .wizard-tab-content {
    padding-top: 10px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .focusx {
    border: 1px solid rgba(var(--vs-primary), 1) !important;
  }
}
</style>
